import React from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import Layout from "views/Layout";

import ContentPage from "../pageTemplates/ContentPage";

const HomeIndexDE = ({ data }) => {
  const {
    markdownRemark: {
      html,
      frontmatter: {
        seoData,
        header,
      },
    },
  } = data;

  return (
    <Layout
      langKey="de"
      seo={seoData}
    >
      <ContentPage
        header={header}
        html={html}
      />
    </Layout>
  );
};

export default HomeIndexDE;

HomeIndexDE.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query privacyPolicyPageQuery {
    markdownRemark(
      fileAbsolutePath: {regex: "/privacy-policy.md$/" }
    ) {
      html
      frontmatter {
        seoData {
          title
        }
        header
      }
    }
  }
`;
